import { TimeoffContactInfo } from './../@models/timeoff-request.model';
import { EmployeeModel } from './../@models/employee.model';
import { TimeoffHistoryModel } from './../@models/timeoff-history.model';
import { TimeoffPolicyModel } from './../@models/timeoff-policy.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { UserInfoService } from './user-info.service';
import { PtoRequestModel } from '@app/@models/pto-request.model';

@Injectable({
  providedIn: 'root',
})
export class TimeoffService {
  constructor(
    private http: HttpClient,
    private userInfoService: UserInfoService,
    private helperService: HelperService
  ) {}

  getPtoPolicies(eeSysId?: number): Observable<TimeoffPolicyModel[]> {
    // console.log({
    //   getPtoPolicies: 'getPtoPolicies',
    //   eeSysId: eeSysId,
    //   'this.userInfoService.userInfo.eeSysId': this.userInfoService.userInfo.eeSysId,
    // });
    return this.http
      .post<TimeoffPolicyModel[]>(environment.serverUrl + 'timeoff/myptopolicies', {
        eeSysId: eeSysId ? eeSysId : this.userInfoService.userInfo.eeSysId,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getEmployeesTimeOff(managerId: number) {
    return this.http
      .post<EmployeeModel[]>(environment.serverUrl + 'timeoff/getEmployeesTimeOff', {
        managerId,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPtoPolicyHistory(
    y: number = this.helperService.getCurrentYear(),
    ppid?: number
  ): Observable<TimeoffHistoryModel[]> {
    // console.log('In service getPtoHistory-policyId:' + ppid);
    return this.http
      .post<TimeoffHistoryModel[]>(environment.serverUrl + 'timeoff/myptohistory', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        eeSysId: this.userInfoService.userInfo.eeSysId,
        ptoPolicyId: ppid,
        year: y,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPtoPolicyHistoryByYear(
    y: number = this.helperService.getCurrentYear(),
    ppid: number,
    useYear: boolean
  ): Observable<TimeoffHistoryModel[]> {
    // console.log('In service getPtoHistory-policyId:' + ppid);
    return this.http
      .post<TimeoffHistoryModel[]>(environment.serverUrl + 'timeoff/myptohistory', {
        companyId: this.userInfoService.userInfo.companyId,
        employeeId: this.userInfoService.userInfo.employeeId,
        eeSysId: this.userInfoService.userInfo.eeSysId,
        ptoPolicyId: ppid,
        year: y,
        useYear,
        token: this.userInfoService.userInfo.token,
      })
      .pipe(
        map((res) => res),
        shareReplay()
      ); // shareReplay limits the calls to the server to one.
  }

  getPtoRequest(year: number = this.helperService.getCurrentYear()) {
    return this.http.post<PtoRequestModel[]>(environment.serverUrl + 'timeoff/myptorequest', {
      username: this.userInfoService.userInfo.username,
      companyId: this.userInfoService.userInfo.companyId,
      eeSysId: this.userInfoService.userInfo.eeSysId,
      managerId: this.userInfoService.userInfo.managerId,
      // ptoPolicyId: ppid,
      isManager: 0,
      year,
      token: this.userInfoService.userInfo.token,
    });
  }

  getSinglePtoRequest(requestID: number) {
    return this.http.post<PtoRequestModel>(environment.serverUrl + 'timeoff/myptorequest', {
      username: this.userInfoService.userInfo.username,
      companyId: this.userInfoService.userInfo.companyId,
      eeSysId: this.userInfoService.userInfo.eeSysId,
      managerId: this.userInfoService.userInfo.managerId,
      // ptoPolicyId: ppid,
      isManager: 0,
      requestID,
      token: this.userInfoService.userInfo.token,
    });
  }

  getEmployeePtoRequest(
    year: number = this.helperService.getCurrentYear(),
    status: string = '',
    monthsBack: number = 3
  ) {
    return this.http.post<PtoRequestModel[]>(environment.serverUrl + 'timeoff/myptorequest', {
      username: this.userInfoService.userInfo.username,
      companyId: this.userInfoService.userInfo.companyId,
      eeSysId: 0,
      managerId: this.userInfoService.userInfo.managerId,
      ptoPolicyId: 0,
      isManager: this.userInfoService.userInfo.isManager,
      year,
      token: this.userInfoService.userInfo.token,
      status,
      monthsBack,
    });
  }

  // TODO fix the return to not be any
  getMyManager(): Observable<any> {
    return this.http.post(environment.serverUrl + 'timeoff/myManager', {
      companyId: this.userInfoService.userInfo.companyId,
      eeSysId: this.userInfoService.userInfo.eeSysId,
      token: this.userInfoService.userInfo.token,
    });
  }

  /**
   * PK is autoincremented at api call
   * @param beginDate First date of time off
   * @param endDate Last date of time off
   * @param ptoPolicyId Time off policy identifier
   * @param hoursRequested How much time is being requested
   * @param reason Reason for time off request
   */
  requestTimeOff(r: PtoRequestModel) {
    const username = this.userInfoService.userInfo.username;
    const token = this.userInfoService.userInfo.token;
    let beginDate = this.helperService.formatDateToD10fromISOString(r.beginDate);
    let endDate = this.helperService.formatDateToD10fromISOString(r.endDate);
    let action = 'timeoff/addTimeoffRequest';
    if (r.requestId > 0) {
      action = 'timeoff/editTimeoffRequest';
    }
    // console.log(action);

    try {
      return this.http
        .post<any>(environment.serverUrl + action, {
          requestId: r.requestId,
          beginDate: beginDate,
          endDate: endDate,
          ptoPolicyId: r.ptoPolicyId,
          hoursRequested: r.hoursRequested,
          reason: r.reasonRequested,
          approvedBy: r.approvedBy,
          managerNotes: r.managerNotes,
          status: r.status,
          employeeId: r.employeeId,
          companyId: r.companyId,
          username,
          token,
        })
        .pipe(
          tap(() => console.log('HTTP request executed')),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}
  }

  /**
   * @param requestId The request identifier
   * @param beginDate First date of time off
   * @param endDate Last date of time off
   * @param ptoPolicyId Time off policy identifier
   * @param hoursRequested How much time is being requested
   * @param reason Reason for time off request
   */
  editTimeOffRequest(
    requestId: number,
    beginDate: string,
    endDate: string,
    ptoPolicyId: number,
    hoursRequested: number,
    reason: string
  ) {
    const employeeId = this.userInfoService.userInfo.employeeId;
    const companyId = this.userInfoService.userInfo.companyId;
    const username = this.userInfoService.userInfo.username;
    const token = this.userInfoService.userInfo.token;
    const begin = this.helperService.formatDateToD10fromISOString(beginDate);
    const end = this.helperService.formatDateToD10fromISOString(endDate);
    beginDate = this.helperService.formatDateToD10fromISOString(beginDate);
    endDate = this.helperService.formatDateToD10fromISOString(endDate);

    this.http
      .post(environment.serverUrl + 'timeoff/editTimeoffRequest', {
        requestId,
        beginDate,
        endDate,
        ptoPolicyId,
        hoursRequested,
        reason,
        employeeId,
        companyId,
        username,
        token,
      })
      .subscribe(
        (response) => {
          // console.log(response);
        },
        (error) => {
          alert(JSON.stringify(error));
        }
      );
  }

  convertStatus(r: PtoRequestModel, short: boolean = false) {
    const timeOffStatus: Map<string, string> = new Map([
      ['p', 'Awaiting Approval'],
      ['a', 'Approved'],
      ['r', 'Declined'],
      ['anp', 'Approved Not Paid'],
    ]);
    let status = r.status;
    if (status.toLowerCase() === 'a' && (r.checkNumber === 0 || r.empHoursNumber == 0)) {
      status = 'anp';
    }
    if (status.toLowerCase() === 'a' && r.empHoursNumber > 0) {
      status = 'a';
    }
    if (short) {
      return status.toLowerCase();
    }
    return timeOffStatus.get(status.toLowerCase());
  }

  // Remove PTO Request. Added 09/20/2024. DE1132rea.
  removePTORequest(r: PtoRequestModel) {
    try {
      return this.http
        .post<any>(environment.serverUrl + 'timeoff/removePTORequest', {
          requestId: r.requestId,
          employeeId: r.employeeId,
          companyId: r.companyId,
        })
        .pipe(
          tap(() => console.log('HTTP request executed')),
          map((res) => res),
          shareReplay()
        );
    } catch (err) {}

  }

  getPTORequestEmployeeInfo(ptoRequestId?: number): Observable<TimeoffContactInfo> {
    return this.http.post<TimeoffContactInfo>(environment.serverUrl + 'timeoff/getPTORequestEmployeeInfo', {
      ptoRequestId,
      token: this.userInfoService.userInfo.token,
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-delivery-option',
  templateUrl: './delivery-option.component.html',
  styleUrls: ['./delivery-option.component.scss'],
})
export class DeliveryOptionComponent implements OnInit {
  @Input() info: any;
  deliveryMethod: string;
  eeSysId: number = 0;
  phone: string;
  email: string;
  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    // console.log(this.info);
    if (this.info.length == 1) {
      this.changeUsers(this.info[0].eeSysId);
    }
  }

  changeUsers(v: number) {
    this.eeSysId = v;
    this.deliveryMethod = '';
    // console.log(this.eeSysId);
    this.info.forEach((obj: any) => {
      if (obj.eeSysId === this.eeSysId) {
        this.phone = this.formatPhone(obj.phone, true);
        this.email = this.validateEmail(obj.email) ? this.maskEmail(obj.email) : '';
      }
    });
  }

  setDeliveryOption(v: string) {
    this.deliveryMethod = v;
  }

  onSubmit() {
    // console.log(this.deliveryMethod);
    this.modalController.dismiss({ deliveryMethod: this.deliveryMethod, eeSysId: this.eeSysId });
  }

  dismissModal() {
    this.modalController.dismiss({ deliveryMethod: '', eeSysId: 0 });
  }

  validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  maskEmail(email: string) {
    const [name, domain] = email.split('@');
    const { length: len } = name;
    const maskedName = name[0] + '...' + name[len - 1];
    const maskedEmail = maskedName + '@' + domain;
    return maskedEmail;
  }

  formatPhone(phoneNumberString: string, mask: boolean = false) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      if (mask) {
        return '(XXX) XXX-' + match[3];
      }
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
}

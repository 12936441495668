import { PushNotificationService } from './../../@services/push-notification.service';

import { Component, Input, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Validators, ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';

import { PtoRequestModel, setPtoRequestModelDefaults } from '@app/@models/pto-request.model';
import { TimeoffPolicyModel } from '@app/@models/timeoff-policy.model';
import { UserInfoModel } from '@app/@models/userInfo.model';
import { EmailService } from '@app/@services/email.service';
import { HelperService } from '@app/@services/helper.service';
import { TimeoffService } from '@app/@services/timeoff.service';
import { UserInfoService } from '@app/@services/user-info.service';
import { environment } from '@env/environment';
import { Platform, ModalController, AlertController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { PayrollService } from '@app/@services/payroll.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { SharedModule } from '@app/@shared';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
@Component({
  selector: 'app-pto-request',
  templateUrl: './pto-request.component.html',
  styleUrls: ['./pto-request.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
})
export class PtoRequestComponent implements OnInit, OnDestroy {
  @Input() ptoRequest: PtoRequestModel;
  policies: TimeoffPolicyModel[];
  isManager = false;
  hasManagerControl = false;
  action: string = 'Add'; // View, Edit, Add, Delete
  ready = false;
  production = environment.production;
  isLoading = false;
  version: string | null = environment.version;
  userInfo: UserInfoModel;
  policyCount: number;
  error: null;
  requestForm: FormGroup = new FormGroup({
    beginDate: new FormControl(new Date(), Validators.required),
    endDate: new FormControl(new Date(), Validators.required),
    hoursRequested: new FormControl('', [Validators.required, , Validators.min(0.01), Validators.max(120)]),
    policyId: new FormControl('', Validators.required),
    reason: new FormControl('', [Validators.maxLength(150)]),
    managerNotes: new FormControl(''),
  });

  canEdit = false; // View = false, Add/Edit = true
  selectedPolicyId: number;
  today = new Date();
  month = this.today.getMonth();
  year = this.today.getFullYear();

  // begin = new Date().toISOString();
  // end = new Date().toISOString();
  // minDate = this.helperService.formatDate(this.helperService.getAdjustedDate(-14), 'yyyy-mm-dd');
  // maxDate = this.helperService.formatDate(this.helperService.getAdjustedDate(90), 'yyyy-mm-dd');
  // endMinDate = this.helperService.formatDate(new Date(), 'yyyy-mm-dd');
  // endMaxDate = this.helperService.getPayrollSunday(new Date().toISOString(), 'yyyy-MM-dd');
  submitRequest = false;

  ptoStart: string;
  ptoEnd: string;
  datesSelected = false;
  selected = {
    startDate: dayjs(),
    endDate: dayjs(),
  };
  inValidDate = false;
  ptoEstimate: number = 0;
  payDates: [];
  selectedDate: any;

  public errorMessages = {
    beginDate: [null, { type: 'required', message: 'Start date is required' }],
    endDate: [null, { type: 'required', message: 'End date is required' }],
    policyId: [{ type: 'required', message: 'You must select a time off policy' }],
    hoursRequested: [
      { type: 'required', message: 'Value must be greater than 0.00' },
      { type: 'max', message: `More than 120 hours cannot be requested in a single request.` },
      { type: 'min', message: 'Value must be greater than 0.00' },
    ],
    reason: [
      // { type: 'required', message: 'A reason is required' },
      { type: 'maxlength', message: 'Reason cannot exceed 150 characters' },
      { type: 'minlength', message: 'Reason must be longer than 8 characters.' },
    ],
  };

  rangeFilter = (d: Date | null): boolean => {
    return true;
  };

  constructor(
    private helperService: HelperService,
    private userInfoService: UserInfoService,
    private timeoffService: TimeoffService,
    private payrollService: PayrollService,
    private modalController: ModalController,
    public platform: Platform,
    private emailService: EmailService,
    private alert: AlertController,
    private pushNotificationServices: PushNotificationService
  ) {}

  get beginDate() {
    return this.requestForm.get('beginDate');
  }
  get endDate() {
    return this.requestForm.get('endDate');
  }
  get policyId() {
    return this.requestForm.get('policyId');
  }
  get hoursRequested() {
    return this.requestForm.get('hoursRequested');
  }
  get reason() {
    return this.requestForm.get('reason');
  }
  get managerNotes() {
    return this.requestForm.get('reason');
  }

  ngOnInit(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);

    this.userInfo = this.userInfoService.userInfo;
    this.isManager = this.userInfo.isManager > 0 ? true : false;
    this.checkMangerControls();
    // this.selected = {
    //   startDate: this.ptoRequest.beginDate ? dayjs(this.ptoRequest.beginDate) : dayjs(),
    //   endDate: this.ptoRequest.endDate ? dayjs(this.ptoRequest.endDate) : dayjs(),
    // };

    this.createForm();
    this.datesSelected = true;

    // this.payrollService
    //   .getTimeOffPayrollDates(
    //     this.ptoRequest.endDate,
    //     this.userInfoService.userInfo.employeeId,
    //     this.userInfoService.userInfo.companyId
    //   )
    //   .subscribe((data) => {
    //     this.payDates = data;
    //     console.log(this.ptoRequest.endDate);
    //   });

    // this.patchValues();
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
    this.dismissModal();
  }

  async patchValues() {
    let eeSysId = this.ptoRequest.eeSysId > 0 ? this.ptoRequest.eeSysId : this.userInfoService.userInfo.eeSysId;
    await this.getPtoPolicies(eeSysId);

    this.requestForm.patchValue({
      // Update the local variables
      beginDate: this.ptoRequest.requestId > 0 ? this.helperService.parseDate(this.ptoRequest.beginDate) : null,
      endDate: this.ptoRequest.requestId > 0 ? this.helperService.parseDate(this.ptoRequest.endDate) : null,
      policyId: this.ptoRequest.ptoPolicyId ? String(this.ptoRequest.ptoPolicyId) : '',
      hoursRequested: this.ptoRequest.hoursRequested,
      reason: this.ptoRequest.reasonRequested,
    });
    setTimeout(() => {
      if (this.ptoRequest.ptoPolicyId) {
        this.policyId.setValue(this.ptoRequest.ptoPolicyId, { onlySelf: true });
      }
    }, 500);

    this.selectedPolicyId = this.ptoRequest.ptoPolicyId;
  }

  async getPtoPolicies(eeSysId: number = this.userInfoService.userInfo.eeSysId) {
    this.timeoffService.getPtoPolicies(eeSysId).subscribe(
      (data) => {
        this.policies = data;
        this.policyCount = data.length;
        this.isLoading = false;
        const policyId = this.policyId;

        // policyId.setValue(this.policies[0].ptoPolicyId);
        // if (this.policyCount >= 1) {
        //   policyId.setValue(this.policies[0].ptoPolicyId);
        // }
      },
      (error) => {
        this.error = error.message;
        this.isLoading = false;
      }
    );
  }

  async createForm() {
    // if (this.ptoRequest.requestId == 0) {
    //   this.ptoRequest.beginDate = dayjs().format('YYYY/MM/DD');
    //   this.ptoRequest.endDate = dayjs().format('YYYY/MM/DD');
    // }

    let eeSysId = this.ptoRequest.requestId > 0 ? this.ptoRequest.eeSysId : this.userInfoService.userInfo.eeSysId;
    await this.getPtoPolicies(eeSysId);
    await this.patchValues();

    this.requestForm.get('hoursRequested').markAsTouched();

    // this.requestForm.get('beginDate').valueChanges.subscribe((val) => {
    //   this.requestForm.get('endDate').setValue(val);
    //   this.endMinDate = this.helperService.getStringToFormatDate(val, 'yyyy-MM-dd');
    //   this.endMaxDate = this.helperService.getPayrollSunday(val, 'yyyy-MM-dd');
    // });

    this.requestForm.get('policyId').valueChanges.subscribe((val) => {
      if (this.policies && val > 0) {
        this.selectedPolicyId = val;
        let c = this.requestForm.get('hoursRequested');
        let p = this.policies.find((obj: any) => obj.ptoPolicyId == val);
        this.ptoRequest.ptoPolicyName = p.policyName;

        if (this.selectedDate) {
          this.estimatePto();
        }

        // Added logic for zero accrue policies. Zero accrue policy requests cannot
        // request more than the balance of the policy. Ron Adesso. 08/02/2021.
        if (p.earnedPerHour === 0.0) {
          c.setValidators([Validators.required, Validators.min(0.01), Validators.max(p.balance)]);
          this.errorMessages.hoursRequested[1].message = `Exceeds your balance of ${p.balance} hours.`;
          c.setValue(c.value);
        } else {
          c.setValidators([Validators.required, Validators.min(0.01), Validators.max(120)]);
          this.errorMessages.hoursRequested[1].message = `The max you can request is 120 hours.`;
          c.setValue(c.value);
        }
      }
    });
  }

  cancelUpdate() {
    this.requestForm.reset();
    this.dismissModal();
  }

  onSubmit() {
    this.isLoading = true;
    // console.warn(this.requestForm.value); // Gets all values defined for the form in an array.
    if (this.requestForm.invalid) {
      return;
    } // Do not proceed if invalid

    if (this.requestForm.pristine) {
      // this.alertMsg('Warning', 'Nothing has been changed.');
      // return;
    }

    this.submitRequest = true;

    if (!this.ptoRequest) {
      this.ptoRequest = setPtoRequestModelDefaults();
    }

    this.ptoRequest.beginDate = this.helperService.formatDate(this.beginDate.value, 'yyy-MM-dd');
    this.ptoRequest.endDate = this.helperService.formatDate(this.endDate.value, 'yyy-MM-dd');
    this.ptoRequest.ptoPolicyId = this.policyId.value;
    this.ptoRequest.hoursRequested = this.hoursRequested.value;
    this.ptoRequest.reasonRequested = this.reason.value;
    this.ptoRequest.requestId = this.ptoRequest ? this.ptoRequest.requestId : 0;
    this.ptoRequest.managerNotes = this.managerNotes.value;

    // If an employee edits their request when it is already approved, set it back to pending.
    if (!this.isManager && this.ptoRequest.status.toLowerCase() === 'a') {
      this.ptoRequest.status = 'P';
    }

    //get the value of begin and end date, then split the string to extract the year.
    let beginDate = new Date(this.beginDate.value);
    let endDate = new Date(this.endDate.value);

    if (beginDate.getFullYear() !== endDate.getFullYear()) {
      this.alertDenied('Request Denied', 'Please make a seperate request for different years.');
      this.submitRequest = false;
      return;
    }

    // adding one day because if you select the 15 and the 16 the difference would be 1 but it is 2 days of pto
    let days =
      this.helperService.getDifferenceInDays(new Date(this.ptoRequest.endDate), new Date(this.ptoRequest.beginDate)) +
      1;

    if (this.ptoRequest.hoursRequested > days * 8) {
      this.alertMsg(
        'Warning',
        'You have requested ' +
          this.ptoRequest.hoursRequested +
          ' hours for ' +
          days +
          ' days. Would you like to continue?'
      );
    } else {
      this.finishSubmitiing();
    }
  }

  finishSubmitiing() {
    this.timeoffService.requestTimeOff(this.ptoRequest).subscribe((res: any) => {
      if (res.success) {
        this.ptoRequest.requestId = this.ptoRequest.requestId == 0 ? res.requestId : this.ptoRequest.requestId;
        // console.log('finish submitting', res, this.ptoRequest.requestId);
        this.timeoffService.getPTORequestEmployeeInfo(this.ptoRequest.requestId).subscribe((d) => {
          this.emailService.ptoRequest(this.ptoRequest, d[0]);
          this.pushNotificationServices.requestTimeOff(this.ptoRequest);
        });

        this.dismissModal();
      }
    });
  }

  async alertMsg(header: string, message: string) {
    const alert = await this.alert.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'secondary',
          handler: () => {
            this.submitRequest = false;
          },
        },
        {
          text: 'Confirm',
          cssClass: 'primary',
          handler: () => {
            this.finishSubmitiing();
          },
        },
      ],
    });
    await alert.present();
    return;
  }

  approveReject(status: string) {
    this.ptoRequest.status = status;
    this.ptoRequest.approvedBy = this.userInfoService.userInfo.username;
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.modalController.dismiss({ request: this.ptoRequest, submitRequest: this.submitRequest });
  }

  selectBeginDate(dt: MatDatepickerInputEvent<Date>) {
    this.datesSelected = false;

    let val = this.helperService.getFormatDateFromDate(dt.value, 'yyyy-MM-dd');
    let d = this.helperService.parseDate(val);
    let sunday: Date;
    if (d.getDay() === 0) {
      sunday = d;
    } else {
      sunday = this.helperService.getNextDayOfWeekByDate(d, 0);
    }

    this.rangeFilter = (d1: Date | null): boolean => {
      // console.log(d1, sunday);
      return d1 <= sunday;
    };
  }

  selectEndDate(d: MatDatepickerInputEvent<Date>) {
    this.datesSelected = true;
    this.inValidDate = false;
    this.estimatePto();
  }

  clearDates() {
    this.beginDate.setValue('');
    this.endDate.setValue('');
    this.datesSelected = false;
    this.ptoEstimate = 0;
    this.rangeFilter = (d1: Date | null): boolean => {
      return true;
    };
  }

  choosedDate(d: any) {
    this.selectedDate = d;
    let sunday = this.helperService.getPayrollSunday(d.startDate.$d, 'yyyy-MM-dd');

    this.beginDate.setValue(this.helperService.getStringToFormatDate(d.startDate.$d, 'yyyy-MM-dd'));
    this.endDate.setValue(this.helperService.getStringToFormatDate(d.endDate.$d, 'yyyy-MM-dd'));
    this.datesSelected = true;
    this.inValidDate = false;
    this.selected = {
      startDate: dayjs(d.startDate.$d),
      endDate: dayjs(d.endDate.$d),
    };
    if ((d.startDate.$W === 0 && d.endDate.diff(d.startDate.$d, 'day') > 0) || d.endDate.diff(sunday, 'day') > 0) {
      this.endDate.setValue(sunday);
      this.selected.endDate = dayjs(sunday);
      this.inValidDate = true;
      this.datesSelected = false;
    }

    this.estimatePto();
  }

  estimatePto() {
    var collectDays = 0;

    this.payrollService
      .getTimeOffPayrollDates(
        this.endDate.value,
        this.userInfoService.userInfo.employeeId,
        this.userInfoService.userInfo.companyId
      )
      .subscribe((data) => {
        this.payDates = data;
        collectDays = data.length ? data[0].collectDays : 0;

        var weekRate = 40;
        var biWeekRate = 80;
        var rate = 0;
        this.ptoEstimate = 0;

        this.policies.forEach((obj) => {
          let a = obj['ptoPolicyId'];
          let b = this.selectedPolicyId;

          if (a === b) {
            if (obj['employeePayFrequency'] === 'W') {
              rate = weekRate;
            } else if (obj['employeePayFrequency'] === 'B') {
              rate = biWeekRate;
            }
            if (rate > 0) {
              this.ptoEstimate = obj['earnedPerHour'] * rate * collectDays;
              this.ptoEstimate = Math.floor(this.ptoEstimate + obj['balance']);
              if (this.ptoEstimate === obj['balance']) {
                this.ptoEstimate = 0;
              }
            }
          }
        });
      });
  }

  async alertDenied(header: string, message: string) {
    const alert = await this.alert.create({
      header,
      message,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });
    await alert.present();
    return;
  }

  checkMangerControls() {
    this.hasManagerControl = false;

    if (this.userInfoService.userInfo.isManager === this.ptoRequest.managerId) {
      this.hasManagerControl = true;
    }
  }
}

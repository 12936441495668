import { PushNotificationService } from './../../@services/push-notification.service';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { PtoRequestModel, setPtoRequestModelDefaults } from '@app/@models/pto-request.model';
import { EmailService } from '@app/@services/email.service';
import { HelperService } from '@app/@services/helper.service';
import { TimeoffService } from '@app/@services/timeoff.service';
import { UserInfoService } from '@app/@services/user-info.service';
import { AlertController, ModalController, IonSelect, IonicModule } from '@ionic/angular';
import { EmailComponent } from '../email/email.component';
import { PtoRequestComponent } from '../pto-request/pto-request.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { LoaderService } from '@app/@services/loader.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@app/@shared';
import { EmailModule } from '../email/email.module';
import { YearSelectModule } from '../year-select/year-select.module';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
@Component({
  selector: 'app-pto-approval',
  templateUrl: './pto-approval.component.html',
  styleUrls: ['./pto-approval.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    SharedModule,
    EmailModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    YearSelectModule,
    PtoRequestComponent,
  ],
})
export class PtoApprovalComponent implements OnInit {
  @Input() managerView = false;
  @Input() isModal = true;
  @ViewChild('ptoStatusSelect') selectRef: IonSelect;
  title = 'Time Off Request Management';
  ptoRequest: PtoRequestModel[];
  filteredPtoRequest: PtoRequestModel[];
  selectedPtoRequest: PtoRequestModel;
  isLoading = false;
  isManager: boolean = false;
  hideEmployees = false;
  filterStatusString: string;
  year: number;
  hasManagerControl = false;

  requestId: number;
  requestStatus: string;

  dataSource = new MatTableDataSource([setPtoRequestModelDefaults()]);
  displayedColumns: string[] = ['select', 'name', 'policyName', 'status', 'date', 'hours', 'actions'];
  selection = new SelectionModel<PtoRequestModel>(true, []);
  expandedElement: PtoRequestModel | null;

  ptoStatus: Map<string, string> = new Map([
    ['p', 'Pending'],
    ['a', 'Paid'],
    ['r', 'Declined'],
    ['anp', 'Approved Not Paid'],
    ['all', 'All'],
  ]);

  ptoMonthPrior = new Date();
  monthsBack = 3;

  constructor(
    public helperService: HelperService,
    private timeoffService: TimeoffService,
    private userInfoService: UserInfoService,
    private emailService: EmailService,
    private alertController: AlertController,
    private modalController: ModalController,
    private pushNotificationService: PushNotificationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.ptoMonthPrior.setMonth(this.ptoMonthPrior.getMonth() - this.monthsBack);
    if (this.isModal) {
      this.isAModal();
    }

    this.isManager = this.userInfoService.userInfo.isManager > 0 ? true : false;
    // this will remove the checkboxes if not a manager
    if (!this.isManager) {
      this.displayedColumns.splice(0, 1);
    }

    let cond = sessionStorage.getItem('filterStatusString');
    this.filterStatusString = cond === null ? 'P' : cond;

    this.helperService.castActiveYear.subscribe((yr) => {
      this.year = yr;
      this.getPto();
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  isAModal() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  checkMangerControls(managerId: number) {
    if (this.isManager && this.userInfoService.userInfo.isManager === managerId) {
      return true;
    }

    return false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PtoRequestModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.requestId}`;
  }

  ngOnDestroy(): void {
    this.modalController.dismiss();
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    if (this.isModal) {
      this.modalController.dismiss();
    }
  }

  getPto() {
    if (this.managerView && this.isManager) {
      this.getEmployeePtoRequest();
    } else {
      this.getPtoRequest();
    }
  }

  getPtoRequest() {
    this.timeoffService.getPtoRequest(this.year).subscribe((data) => {
      this.ptoRequest = data;
      this.filteredPtoRequest = data;
      this.filterStatus(this.filterStatusString);
    });
  }

  getEmployeePtoRequest() {
    this.timeoffService.getEmployeePtoRequest(0, '', this.monthsBack).subscribe((data) => {
      // Remove user record if it exists
      // console.log(data);
      data = data.filter((r) => r.employeeId !== this.userInfoService.userInfo.employeeId);

      this.ptoRequest = data;
      this.filteredPtoRequest = data;
      this.filterStatus(this.filterStatusString);

      this.autoApproveDecline();
    });
  }

  changeMonthsBack(months: number) {
    this.monthsBack = months;
    this.ptoMonthPrior = new Date();
    this.ptoMonthPrior.setMonth(this.ptoMonthPrior.getMonth() - this.monthsBack);
    this.getEmployeePtoRequest();
  }

  autoApproveDecline() {
    if (this.requestId > 0) {
      this.ptoRequest.forEach((obj) => {
        if (obj.requestId === this.requestId) {
          // console.log(obj);
          if (obj.status !== 'P') {
            this.alert('This request has already been approved or declined');
          } else {
            if (this.requestStatus === 'A') {
              this.approveReject(obj, true);
              this.alert('Request has been approved');
            } else if (this.requestStatus === 'R') {
              this.approveReject(obj, false);
              this.alert('Request has been declined');
            }
          }
        }
      });
    }
  }

  filterStatus(v: string) {
    this.filterStatusString = v.toLowerCase();
    sessionStorage.setItem('filterStatusString', this.filterStatusString);
    // console.log(v);
    this.filterRequest();
  }

  editPto(p: PtoRequestModel) {
    // console.log("PtoRequest Status:" + p.status + "; p.empHoursNumber:" + p.empHoursNumber)
    if (p.status.toLowerCase() === 'a' && p.empHoursNumber > 0) {
      this.alert('This request has already been paid and can not be edited, please contact your manager.');
    } 
    else if (p.empHoursNumber > 0) {
      this.alert('This request is pending payment and can not be edited.');
    }
    else if (!this.isManager && p.status.toLowerCase() === 'a' && p.empHoursNumber === 0) {
      this.alert('This request has already been approved and can not be edited, please contact your manager.');
    }
    else if (!this.isManager && p.status.toLowerCase() === 'r') {
      this.alert('This request has already been declined and can not be edited, please contact your manager.');
    } else {
      this.selectedPtoRequest = p;
      this.openPtoEdit();
    }
  }

  async openPtoEdit() {
    const ptoModal = await this.modalController.create({
      component: PtoRequestComponent,
      componentProps: {
        ptoRequest: this.selectedPtoRequest,
      },
    });

    ptoModal.onDidDismiss().then((data) => {
      this.getPto();
    });
    await ptoModal.present();
    //this.pageChange('requestTimeoff');
  }

  async alert(message: string) {
    let alert = await this.alertController.create({
      message,
      buttons: ['OK'],
    });

    await alert.present();
    return;
  }

  approveReject(r: PtoRequestModel, approve: boolean) {
    if (approve) {
      r.status = 'A';
    } else {
      r.status = 'R';
    }

    r.approvedBy = this.userInfoService.userInfo.username;
    // console.log(r);
    this.timeoffService.requestTimeOff(r).subscribe((res: any) => {
      // console.log(res);
      const index = this.filteredPtoRequest.map((r) => r.requestId).indexOf(r.requestId);
      this.selection.deselect(this.dataSource.data[index]);

      if (res.success) {
        this.timeoffService.getPTORequestEmployeeInfo(r.requestId).subscribe((d) => {
          this.emailService.ptoRequest(r, d[0]);
          this.pushNotificationService.timeoffRequestStatus(r);
        });

        // this.dataSource.data.splice(index, 1);
        // this.dataSource._updateChangeSubscription(); // <-- Refresh the datasource
      }

      if (this.isLoading) {
        this.loaderService.changeMessage(
          'please wait while we process your request ' + this.selection.selected.length + ' remianing'
        );
        if (this.selection.selected.length === 0) {
          this.isLoading = false;
          this.loaderService.hideLoader();
          this.filterRequest();
        }
      }
    });
  }

  filterRequest() {
    // this.dataSource.filter = this.filterStatusString.trim().toLowerCase();
    if (this.filterStatusString == 'all') {
      this.filteredPtoRequest = this.ptoRequest;
    } else {
      this.filteredPtoRequest = this.ptoRequest.filter((obj) => {
        let status = false;

        if (this.filterStatusString === 'anp' && obj.status.toLowerCase() === 'a' && obj.empHoursNumber == 0) {
          status = true;
        } else if (obj.status.toLowerCase() === this.filterStatusString) {
          if (this.filterStatusString === 'a' && obj.status.toLowerCase() === 'a' && obj.empHoursNumber == 0) {
            status = false;
          } else {
            status = true;
          }
        }

        return status;
      });
    }
    this.dataSource = new MatTableDataSource(this.filteredPtoRequest);
  }

  convertStatus(r: PtoRequestModel, short: boolean = false) {
    return this.timeoffService.convertStatus(r, short);
  }

  async emailEmployee(r: PtoRequestModel) {
    //console.log(timeSheet);
    let to = Array(),
      from = this.userInfoService.userInfo.email,
      subject = 'Time Off Request';

    const empName = r.firstName + ' ' + r.lastName;
    to.push({ value: r.employeeEmail, display: empName });

    const emailModal = await this.modalController.create({
      component: EmailComponent,
      componentProps: {
        to,
        from,
        subject,
      },
    });

    emailModal.onDidDismiss().then((data) => {
      // console.log('email closed')
    });
    await emailModal.present();
  }

  async approveRejectAll(approve: boolean) {
    this.loaderService
      .showLoader('please wait while we process your request ' + this.selection.selected.length + ' remianing')
      .then(async () => {
        // this.processRequest();
        this.isLoading = true;
        for (let item of this.selection.selected) {
          // await this.sleep(500);

          this.approveReject(item, approve);
        }
      });
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  displayPtoStatusSelect() {
    this.selectRef.open();
  }

  async removePTORequest(r: PtoRequestModel) {
    let requestDates = (r.beginDate = r.endDate ? r.beginDate : r.beginDate + ' -' + r.endDate);
    let es = this.emailService
    let alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: `Are you sure you want to delete the time off request of ${r.hoursRequested} for ${requestDates}?`,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            this.timeoffService.removePTORequest(r).subscribe((data) => {
              const index = this.dataSource.data.indexOf(r);
              this.dataSource.data.splice(index, 1);
              this.dataSource._updateChangeSubscription(); // <-- Refresh the datasource
              // console.log(data);
            });
          },
        },
      ],
    });

    await alert.present();
      //Send an email to notify parties that request was deleted.
      r.employeeId = r.employeeId * -1; //Change this to negative number to send the email. It is a way to tell a ptoRequest is a removal.
      this.timeoffService.getPTORequestEmployeeInfo(r.requestId).subscribe((d) => {
      this.emailService.ptoRequest(r, d[0]);
      // console.log('PtoRequest details' + JSON.stringify(r));
    return;
      });
  }
}

<ion-header *ngIf="isModal">
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon class="cursor-pointer" slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>{{ title }}</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label>
            Status: {{ ptoStatus.get(filterStatusString) }}
            <span class="link" (click)="displayPtoStatusSelect()">Change</span>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col *ngIf="!managerView">
        <app-year-select color="light" displayLabel="true"></app-year-select>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="managerView && isManager">
      <ion-col>
        Displaying time off requested since {{ ptoMonthPrior | date }}
        <button mat-button [matMenuTriggerFor]="menu">change</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="changeMonthsBack(1)">1 Month</button>
          <button mat-menu-item (click)="changeMonthsBack(3)">3 Months</button>
          <button mat-menu-item (click)="changeMonthsBack(6)">6 Months</button>
          <button mat-menu-item (click)="changeMonthsBack(9)">9 Months</button>
          <button mat-menu-item (click)="changeMonthsBack(12)">1 Year</button>
        </mat-menu>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- Search filter -->
  <div class="row my-5" *ngIf="managerView && isManager">
    <div class="search">
      <th>
        <input id="search-filter" matInput (keyup)="applyFilter($event.target.value)" placeholder="Search by name" />
      </th>
    </div>
  </div>
  <!-- Search filter -->

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <div class="container">
          <mat-checkbox
            *ngIf="managerView"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </div>
      </th>

      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="managerView"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let r">
        {{ r.lastName }}, {{ r.firstName }}
        <!-- <ion-icon (click)="emailEmployee(r)" color="secondary" name="mail"></ion-icon> -->
      </td>
    </ng-container>

    <!-- policyName Column -->
    <ng-container matColumnDef="policyName">
      <th mat-header-cell *matHeaderCellDef>Policy</th>
      <td mat-cell *matCellDef="let r">{{ r.ptoPolicyName }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <ion-select #ptoStatusSelect [value]="filterStatusString" (ionChange)="filterStatus($event.target.value)">
          <ion-select-option *ngFor="let option of ptoStatus | keyvalue" value="{{ option.key }}">{{
            option.value
          }}</ion-select-option>
          <!-- <ion-select-option value="p">Pending</ion-select-option>
          <ion-select-option value="anp"> Approved, Not Paid </ion-select-option>
          <ion-select-option value="a">Paid</ion-select-option>
          <ion-select-option value="r">Declined</ion-select-option>
          <ion-select-option value="all">All</ion-select-option> -->
        </ion-select>
      </th>
      <td mat-cell *matCellDef="let r">
        <span class="pill {{ convertStatus(r, true) }}">{{ convertStatus(r) }}</span>
      </td>
    </ng-container>

    <!-- Dates Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date(s)</th>
      <td mat-cell *matCellDef="let r">
        <span *ngIf="r.beginDate == r.endDate">{{ r.beginDate | date : "MM/dd/yyyy" }}</span>
        <span *ngIf="r.beginDate != r.endDate">
          {{ r.beginDate | date : "MM/dd/yyyy" }} - {{ r.endDate | date : "MM/dd/yyyy" }}
        </span>
      </td>
    </ng-container>

    <!-- Hours Column -->
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef>Hours</th>
      <td mat-cell *matCellDef="let r">{{ r.hoursRequested }}</td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let r">
        <mat-icon
          class="cursor-pointer"
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
          *ngIf="r.empHoursNumber == 0 || r.reasonRequested.length > 3"
        >
          more_vert
        </mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="emailEmployee(r)" *ngIf="checkMangerControls(r.managerId) && managerView">
            <mat-icon>email</mat-icon>
            <span>Email</span>
          </button>
          <button mat-menu-item (click)="editPto(r)" *ngIf="r.empHoursNumber == 0">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button
            mat-menu-item
            (click)="approveReject(r, true)"
            *ngIf="checkMangerControls(r.managerId) && r.status == 'P' && managerView"
          >
            <mat-icon>thumb_up</mat-icon>
            <span>Approve</span>
          </button>
          <button
            mat-menu-item
            (click)="approveReject(r, false)"
            *ngIf="checkMangerControls(r.managerId) && r.status == 'P' && managerView"
          >
            <mat-icon>thumb_down</mat-icon>
            <span>Decline</span>
          </button>
          <button mat-menu-item (click)="removePTORequest(r)" *ngIf="r.empHoursNumber == 0">
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
          <button
            mat-menu-item
            (click)="expandedElement = expandedElement === r ? null : r"
            *ngIf="r.reasonRequested.length > 3"
          >
            <mat-icon>assignment</mat-icon>
            <span>Details</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actionsHeader">
      <th mat-header-cell *matHeaderCellDef colspan="6" class="text-center">
        <ion-select [value]="filterStatusString" (ionChange)="filterStatus($event.target.value)">
          <ion-select-option value="p">Pending</ion-select-option>
          <ion-select-option value="anp">Approved, Not Paid </ion-select-option>
          <ion-select-option value="a">Paid</ion-select-option>
          <ion-select-option value="r">Declined</ion-select-option>
          <ion-select-option value="all">All</ion-select-option>
        </ion-select>
      </th>
    </ng-container>
    <ng-container matColumnDef="actionsFooter">
      <td mat-footer-cell *matFooterCellDef colspan="6" class="text-center">
        <div class="container" *ngIf="isManager">
          <button class="col" *ngIf="selection.selected.length >= 1" mat-menu-item (click)="approveRejectAll(true)">
            <mat-icon>thumb_up</mat-icon>
            <span>
              Approve
              <ion-badge color="primary">{{ this.selection.selected.length }}</ion-badge>
            </span>
          </button>
          <button class="col" *ngIf="selection.selected.length >= 1" mat-menu-item (click)="approveRejectAll(false)">
            <mat-icon>thumb_down</mat-icon>
            <span>
              Decline
              <ion-badge color="danger">{{ this.selection.selected.length }}</ion-badge>
            </span>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let r" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="r == expandedElement ? 'expanded' : 'collapsed'">
          <div class="element-description">
            <ul>
              <li>Policy: {{ r.ptoPolicyName }}</li>
              <li>Reason: {{ r.reasonRequested }}</li>
              <li>Hours: {{ r.hoursRequested }}</li>
              <li>
                <span *ngIf="r.beginDate == r.endDate">Date: {{ r.beginDate | date : "MM/dd/yyyy" }}</span>
                <span *ngIf="r.beginDate != r.endDate">
                  Dates: {{ r.beginDate | date : "MM/dd/yyyy" }} - {{ r.endDate | date : "MM/dd/yyyy" }}
                </span>
              </li>
              <li *ngIf="r.managerNotes.length > 3">Manager Notes: {{ r.managerNotes }}</li>
            </ul>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- <tr mat-header-row *matHeaderRowDef="['actionsHeader']; sticky: true"></tr> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      class="element-row"
      *matRowDef="let row; columns: displayedColumns"
      (click)="selection.toggle(row)"
      [class.expanded-row]="expandedElement === row"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">No data matching the filter</td>
    </tr>
    <tr mat-footer-row *matFooterRowDef="['actionsFooter']; sticky: true"></tr>

    <!-- <tr mat-footer-row matFooterRowDef="sticky:true">
      <th mat-footer-cell [attr.rowspan]="6">

      </th>
    </tr> -->
  </table>
  <!-- <ion-item color="light" class="sticky">
    <ion-grid class="sticky" color="light">
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-select [value]="filterStatusString" (ionChange)="filterStatus($event.target.value)">
              <ion-select-option value="p">Awaiting Approval</ion-select-option>
              <ion-select-option value="anp"> Approved, Not Paid </ion-select-option>
              <ion-select-option value="a">Paid</ion-select-option>
              <ion-select-option value="r">Declined</ion-select-option>
              <ion-select-option value="all">All</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  <ion-list>
    <h3 class="text-center" *ngIf="filteredPtoRequest && filteredPtoRequest.length == 0">
      You have no time off request in {{ year }}.
    </h3>
    <ion-item *ngFor="let r of filteredPtoRequest" color="light">
      <ion-card style="width: 100%">
        <ion-card-content>
          <ion-item lines="none">
            <ion-icon class="cursor-pointer" slot="end" name="arrow-forward" (click)="editPto(r)"></ion-icon>
            <ion-grid class="pto-request">
              <ion-row *ngIf="isManager">
                <ion-col>
                  <ion-card-title>{{ r.lastName }}, {{ r.firstName }}</ion-card-title>
                  <ion-card-subtitle class="cursor-pointer" (click)="emailEmployee(r)">
                    <ion-icon color="secondary" name="mail"></ion-icon> Email {{ r.firstName }}
                  </ion-card-subtitle>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="r.reasonRequested.length > 3">
                <ion-col> {{ r.reasonRequested }} </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="cursor-pointer link" (click)="editPto(r)">
                  <span *ngIf="r.beginDate == r.endDate">{{ r.beginDate | date: "MM/dd/yyyy" }}</span>
                  <span *ngIf="r.beginDate != r.endDate">
                    {{ r.beginDate | date: "MM/dd/yyyy" }} - {{ r.endDate | date: "MM/dd/yyyy" }}
                  </span>

                  ({{ r.hoursRequested }} hour(s))<ion-icon name="pencil"></ion-icon>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  {{ r.ptoPolicyName }} <span class="pill {{ convertStatus(r, true) }}">{{ convertStatus(r) }}</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-grid>
            <ion-row *ngIf="checkMangerControls(r.managerId) && r.status == 'P'">
              <ion-col size="6">
                <ion-button expand="full" color="primary" (click)="approveReject(r, true)">
                  <ion-icon name="checkmark"></ion-icon>
                  <ion-label>Approve</ion-label>
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button expand="full" color="danger" (click)="approveReject(r, false)">
                  <ion-icon name="close"></ion-icon>
                  <ion-label>Decline</ion-label>
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-item>
  </ion-list> -->
</ion-content>

<ion-header *ngIf="isModal">
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>Employees</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <!-- Searchbar with cancel button always shown -->
  <ion-searchbar placeholder="Filter Employees" (keyup)="filterEmployees($event)"></ion-searchbar>
  <!-- <p>
    <mat-form-field appearance="fill">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="filterEmployees($event)" placeholder="Ex. ium" #input />
    </mat-form-field>
  </p> -->

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}, {{ element.firstName }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="policyName">
      <th mat-header-cell *matHeaderCellDef>Policy</th>
      <td mat-cell *matCellDef="let element">{{ element.policyName }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>{{ abbrivateHeader("Balance") }}</th>
      <td mat-cell *matCellDef="let element">{{ element.balance | number: "1.2" }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="approvedBalance">
      <th mat-header-cell *matHeaderCellDef>{{ abbrivateHeader("Approved") }}</th>
      <td mat-cell *matCellDef="let element">{{ element.approvedBalance | number: "1.2" }}</td>
    </ng-container>

    <!-- pendingBalance Column -->
    <ng-container matColumnDef="pendingBalance">
      <th mat-header-cell *matHeaderCellDef>{{ abbrivateHeader("Pending") }}</th>
      <td mat-cell *matCellDef="let element">{{ element.pendingBalance | number: "1.2" }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="5">No data matching the filter "{{ filterValue }}"</td>
    </tr>
  </table>

  <!-- <ion-list>
    <div *ngFor="let e of employees">
      <ion-item *ngIf="e.filter" class="cursor-pointer" (click)="onSelect(e)">
        <ion-label>
          <ion-grid>
            <ion-row>
              <ion-col col-8 col-xl-8 col-lg-3 col-md-4> {{ e.lastName }}, {{ e.firstName }} </ion-col>
              <ion-col col-4 col-xl-4 col-lg-3 col-md-4 class="text-right">{{ e.policyName }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col col-8 col-xl-8 col-lg-3 col-md-4> {{ e.lastName }}, {{ e.firstName }} </ion-col>
              <ion-col col-4 col-xl-4 col-lg-3 col-md-4 class="text-right">{{ e.policyName }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="flex-container">
                  <div>Balance</div>
                  <div>{{ e.balance }}</div>
                </div>
                <div class="flex-container">
                  <div>Approved</div>
                  <div>{{ e.approvedBalance }}</div>
                </div>
                <div class="flex-container">
                  <div>Pending</div>
                  <div>{{ e.pendingBalance }}</div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-label>
        
      </ion-item>
    </div>
    <ion-item *ngIf="!employees || !employees.length" class="text-center">
      <ion-label>No Employee(s) Found</ion-label>
    </ion-item>
  </ion-list> -->
</ion-content>

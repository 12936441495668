import { AlertController } from '@ionic/angular';
import { ValidatePassword } from './../../@services/validation-custom.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserInfoService } from '@app/@services/user-info.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  @Input() eeSysId: number;
  @Input() loginname: string;
  @Output() activePage = new EventEmitter<string>();
  myForm: UntypedFormGroup;
  hide = true;
  cHide = true;

  errorMessages = {
    password: [
      { type: 'hasNumber', message: 'Must be at least 1 number' },
      { type: 'hasUpper', message: 'Must be at least 1 Capital Case' },
      { type: 'hasLower', message: 'Must be at least 1 Lower Case' },
      // { type: 'hasSpecial', message: 'Must be at least 1 Special Character' },
      { type: 'minLength', message: 'Must be at least 8 characters' },
      { type: 'maxLength', message: 'Can not be more than 20 characters' },
      { type: 'hasWhiteSpace', message: 'Password must not contain spaces' },
      {
        type: 'forBiddenCharacter',
        message: 'Password may only include these special characters: ! @ # $ % ^ & * ( ) _ + - =',
      },
    ],
    cpassword: [{ type: 'mustMatch', message: 'Password does not match' }],
  };

  constructor(
    private fb: UntypedFormBuilder,
    private alert: AlertController,
    private userInfoService: UserInfoService
  ) {
    this.myForm = fb.group(
      {
        password: this.fb.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
        cpassword: this.fb.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
      },
      {
        validators: [ValidatePassword.mustMatch, ValidatePassword.passwordStrengthValidator],
      }
    );
  }

  get password() {
    return this.myForm.get('password');
  }

  get cpassword() {
    return this.myForm.get('cpassword');
  }

  ngOnInit(): void {}

  onSubmit() {
    // console.log(this.myForm);
    if (this.myForm.valid) {
      this.userInfoService.resetPassword(this.eeSysId, this.password.value, this.loginname).subscribe({
        next: (res) => {
          this.passwordChanged();
        },
        error: (err) => {
          this.errorPassword();
        },
      });
    }
  }

  goBack() {
    this.activePage.emit('login');
  }

  async passwordChanged() {
    // console.log(this.userInfoService.userInfo.username);
    const alert = await this.alert.create({
      header: 'Password Changed',
      message: 'Your password has been successfully changed.',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.activePage.emit('login');
          },
        },
      ],
    });

    await alert.present();
  }

  async errorPassword() {
    // console.log(this.userInfoService.userInfo.username);
    const alert = await this.alert.create({
      header: 'Error',
      message: 'An error occured while changing your password.  Please contact support.',
      buttons: [
        {
          text: 'Ok',
        },
      ],
    });

    await alert.present();
  }
}

import { HelperService } from './../../@services/helper.service';
import { EmployeeModel } from './../../@models/employee.model';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TimeoffService } from '@app/@services/timeoff.service';
import { ModalController, IonicModule } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@app/@shared';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, SharedModule, MatTableModule, MatFormFieldModule, MatInputModule],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
})
export class EmployeesComponent implements OnInit, OnDestroy {
  @Input() managerId: number;
  @Input() isModal = true;

  employees: EmployeeModel[];
  filterValue = '';
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['fullName', 'policyName', 'balance', 'approvedBalance', 'pendingBalance'];
  headerAbrv: Map<string, string> = new Map([
    ['Balance', 'Bal'],
    ['Approved', 'Appr'],
    ['Pending', 'Pen'],
  ]);

  constructor(
    private timeoffService: TimeoffService,
    private modalController: ModalController,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    if (this.isModal) {
      this.isAModal();
    }

    this.timeoffService.getEmployeesTimeOff(this.managerId).subscribe((data) => {
      this.employees = data;
      this.dataSource = new MatTableDataSource(data);
      // this.filterEmployees('');
    });
  }

  isAModal() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }

  abbrivateHeader(text: string) {
    if (this.helperService.checkIfVisible('sm')) {
      return text;
    } else {
      return this.headerAbrv.get(text);
    }
  }

  filterEmployees(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    // let search = val.toLowerCase();
    // this.employees.forEach((e) => {
    //   if (
    //     e.firstName.toLowerCase().includes(search) ||
    //     e.lastName.toLowerCase().includes(search) ||
    //     e.policyName.toLowerCase().includes(search) ||
    //     val.length === 0
    //   ) {
    //     e.filter = true;
    //   } else {
    //     e.filter = false;
    //   }
    // });
  }

  onSelect(employee: EmployeeModel) {
    this.modalController.dismiss({ employee });
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    if (this.isModal) {
      this.modalController.dismiss();
    }
  }
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UserInfoService } from '@app/@services/user-info.service';
import { ValidatePassword } from '@app/@services/validation-custom.service';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  myForm: FormGroup = new FormGroup(
    {
      opassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
      cpassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
    },
    {
      validators: [ValidatePassword.mustMatch, ValidatePassword.passwordStrengthValidator],
    }
  );

  errorMessages = {
    password: [
      { type: 'hasNumber', message: 'Must be at least 1 number' },
      { type: 'hasUpper', message: 'Must be at least 1 Capital Case' },
      { type: 'hasLower', message: 'Must be at least 1 Lower Case' },
      // { type: 'hasSpecial', message: 'Must be at least 1 Special Character' },
      { type: 'minLength', message: 'Must be at least 8 characters' },
      { type: 'maxLength', message: 'Can not be more than 20 characters' },
      { type: 'hasWhiteSpace', message: 'Password must not contain spaces' },
      {
        type: 'forBiddenCharacter',
        message: 'Password may only include these special characters: ! @ # $ % ^ & * ( ) _ + - =',
      },
    ],
    cpassword: [{ type: 'mustMatch', message: 'Password does not match' }],
  };
  hide = [true, true, true];

  constructor(
    private alert: AlertController,
    private userInfoService: UserInfoService,
    private modalController: ModalController
  ) {}

  get opassword() {
    return this.myForm.get('opassword');
  }

  get password() {
    return this.myForm.get('password');
  }

  get cpassword() {
    return this.myForm.get('cpassword');
  }

  ngOnInit(): void {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }

  onSubmit() {
    // console.log(this.myForm);
    if (this.myForm.valid) {
      // If this is a supervisor the related System id will be supervisorSysId else emploee SysId

      const sysId =
        this.userInfoService.userInfo.loginType.toLowerCase() === 'employee'
          ? this.userInfoService.userInfo.eeSysId
          : this.userInfoService.userInfo.supervisorSysId;

      this.userInfoService
        .changePassword(sysId, this.password.value, this.userInfoService.userInfo.username, this.opassword.value)
        .subscribe({
          next: (data) => {
            this.passwordChanged();
          },
          error: (err) => {
            this.pwChangeError();
          },
        });
      // this.passwordChanged();
    }
  }

  async pwChangeError() {
    // console.log(this.userInfoService.userInfo.username);
    const alert = await this.alert.create({
      header: 'Error',
      message: 'We could not verify your information.',
      buttons: [
        {
          text: 'Ok',
        },
      ],
    });

    await alert.present();
  }

  async passwordChanged() {
    // console.log(this.userInfoService.userInfo.username);
    const alert = await this.alert.create({
      header: 'Password Changed',
      message: 'Your password has been sucessfully changed.',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.dismissModal();
          },
        },
      ],
    });

    await alert.present();
  }

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.modalController.dismiss();
  }
}
